<div class="question-modal-container">
  <app-modal-header title="Ask a Question" [showCloseButton]="true"></app-modal-header>
  <div class="qa-modal-body rounded-md" [formGroup]="questionForm">
    <app-input
      [submitted]="submitted"
      title="Question"
      [largeText]="true"
      [control]="questionControl"
      [whiteBackground]="false"
    ></app-input>
    <app-dropdown
      class="w-full"
      title="Prep time"
      hint="(minutes)"
      [submitted]="submitted"
      [items]="prepTimeOpts"
      [control]="prepTimeControl"
      placeholder="Prep time"
    ></app-dropdown>
    <app-dropdown
      title="Response time"
      hint="(minutes)"
      [submitted]="submitted"
      [items]="responseTimeOpts"
      [control]="responseTimeControl"
      placeholder="Response time"
    ></app-dropdown>
    <app-dropdown
      title="Max allowed attempts"
      [submitted]="submitted"
      [items]="attempsOpts"
      [control]="attemptsControl"
      [dropUp]="true"
      placeholder="Allowed attempts"
    ></app-dropdown>

    <div class="mt-4 flex items-center justify-end gap-4">
      <button (click)="closeDialog()" class="secondary-btn">Cancel</button>
      <button (click)="submit()" class="primary-btn">Save</button>
    </div>
  </div>
</div>
