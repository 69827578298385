import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';

import { ModalService } from '../../modal.service';

import { ModalHeaderComponent } from '../../modal-header/modal-header.component';

import { IQuestion } from 'src/app/core/models/question.model';
import { DATA_TOKEN } from '../../modal-container/modal-container.component';

@Component({
  selector: 'app-qa-confirmation-prompt',
  templateUrl: './qa-confirmation-prompt.component.html',
  styleUrls: ['./qa-confirmation-prompt.component.scss'],
  standalone: true,
  imports: [CommonModule, ModalHeaderComponent],
})
export class QaConfirmationPromptComponent {
  constructor(
    @Inject(DATA_TOKEN) public injectedData: { ['question']: IQuestion },
    private modalService: ModalService,
  ) {}

  public onCommit(): void {
    this.modalService.clear(true);
  }

  public close(): void {
    this.modalService.clear();
  }
}
