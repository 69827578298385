import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { VideoService } from 'src/app/candidate/services/video/video.service';
import { IVideoPayload } from 'src/app/core/models/video-api-payloads/video.model';
import { ModalService } from '../../modal.service';
import { DATA_TOKEN } from '../../modal-container/modal-container.component';
import { CommonModule } from '@angular/common';
import { ModalHeaderComponent } from '../../modal-header/modal-header.component';
import { ScrollTrackerDirective } from 'src/app/shared/directives/scroll-tracker.directive';
import { VideoCardComponent } from '../../../video-card/video-card.component';
import { InputComponent } from '../../../input-groups/input/input.component';
import { LoaderComponent } from '../../../loader/loader.component';

@Component({
  selector: 'app-add-video-to-playlist',
  templateUrl: './add-video-to-playlist.component.html',
  styleUrls: ['./add-video-to-playlist.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ModalHeaderComponent,
    ScrollTrackerDirective,
    ReactiveFormsModule,
    forwardRef(() => VideoCardComponent),
    InputComponent,
    LoaderComponent,
  ],
})
export class AddVideoToPlaylistComponent implements OnInit {
  public searchControl: FormControl = new FormControl('');

  public videos: IVideoPayload[] = [];
  public selectedVideos: IVideoPayload[] = [];
  public loading: boolean = true;

  private next?: string;

  constructor(
    private videoService: VideoService,
    private modalService: ModalService,
    @Inject(DATA_TOKEN) public injectedData: { ['id']: string; ['videos']: IVideoPayload[] },
  ) {}

  ngOnInit(): void {
    this.getVideos();
    this.search();
    this.selectedVideos = this.injectedData.videos.map((v) => v);
  }

  private getVideos(): void {
    this.loading = true;
    this.videoService.getVideos().subscribe((res) => {
      this.videos = res.results;
      this.next = res.next;
      this.loading = false;
    });
  }

  public getMoreVideos(): void {
    if (this.next) {
      this.videoService.getPaginatedVideos(this.next).subscribe((res) => {
        this.videos = this.videos.concat(res.results);
        this.next = res.next;
      });
    }
  }

  public selectVideo(video: IVideoPayload): void {
    if (this.selectedVideos.find((vid) => vid.id == video.id)) {
      this.selectedVideos = this.selectedVideos.filter((vid) => vid.id != video.id);
    } else {
      this.selectedVideos.push(video);
    }
  }

  private search(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((searchTerm) => {
          return this.videoService.searchVideos(searchTerm as string);
        }),
      )
      .subscribe((res) => {
        this.videos = res.results;
        this.next = res.next;
      });
  }

  public save(): void {
    let videos = this.selectedVideos.map((v) => v.id);
    let payload = {
      playlistId: this.injectedData.id,
      videos: videos,
    };

    this.videoService.addVideosToPlaylist(payload).subscribe(() => {
      this.modalService.clear(true);
    });
  }

  public cancel(): void {
    this.modalService.clear(false);
  }
}
