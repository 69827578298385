import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { IVideoObject } from 'src/app/candidate/dashboard/pages/my-applications/video-reply/video-reply.component';

import { DATA_TOKEN } from '../../modal-container/modal-container.component';
import { ModalService } from '../../modal.service';
import { ModalHeaderComponent } from '../../modal-header/modal-header.component';

@Component({
  selector: 'app-qa-video-select',
  templateUrl: './qa-video-select.component.html',
  styleUrls: ['./qa-video-select.component.scss'],
  standalone: true,
  imports: [CommonModule, ModalHeaderComponent],
})
export class QaVideoSelectComponent {
  public selectedInd: number = -1;
  public videoUrls: SafeUrl[] = [];

  constructor(
    private modalService: ModalService,
    private sanitizer: DomSanitizer,
    @Inject(DATA_TOKEN) public injectedData: { ['answers']: IVideoObject[] },
  ) {
    this.injectedData.answers.map((answer) => {
      this.videoUrls.push(this.getVideoUrl(answer));
    });
  }

  public onSelect(selectedAnswer: IVideoObject): void {
    this.modalService.clear(selectedAnswer);
  }

  public getImgUrl(answer: IVideoObject) {
    let objectURL = URL.createObjectURL(answer.thumbnail as unknown as Blob);
    return this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  public getVideoUrl(answer: IVideoObject) {
    let objectURL = URL.createObjectURL(answer.video as unknown as Blob);
    return this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  public select(index: number): void {
    if (this.selectedInd === index) {
      this.selectedInd = -1;
    } else {
      this.selectedInd = index;
    }
  }
  public confirm(): void {
    this.modalService.clear(this.selectedInd);
  }
}
