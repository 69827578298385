import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";

import { IdleTimeoutService } from "./core/services/idle-timeout/idle-timeout.service";
import { AuthService } from "./core/services/auth/auth.service";
import { SeoService } from "./core/services/seo/seo.service";

import {
  IModalConfig,
  ModalService,
} from "./shared/components/modal/modal.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent implements OnInit {
  public showModal: boolean = false;
  public modalConfig?: IModalConfig;

  constructor(
    private modalService: ModalService,
    private idleTimeoutService: IdleTimeoutService,
    private authService: AuthService,
    private seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  ngOnInit(): void {
    this.handleModalUpdates();
    if (isPlatformBrowser(this.platformId)) this.intializeIdleTimeoutSettings();
    this.seoService.setDefaultTags();
  }

  private intializeIdleTimeoutSettings(): void {
    this.authService.isAuthenticated$.subscribe((res: boolean) => {
      if (res) {
        this.idleTimeoutService
          .startWatching()
          .subscribe((isTimeOut: boolean) => {
            if (isTimeOut) {
              this.modalService.openIdleTimeOutModal();
            }
          });
      }
    });
  }

  private handleModalUpdates(): void {
    this.modalService.modalOpened$.subscribe((res) => {
      if (res) {
        this.modalConfig = res;
        this.showModal = true;
      } else {
        this.showModal = false;
        this.modalConfig = undefined;
      }
    });
  }
}
