<div class="flex items-start justify-between border-b-[1px] border-gray-200 p-4">
  <div>
    <h3 class="text-lg font-semibold leading-6 text-gray-900">{{ title }}</h3>
    <legend class="text-xs font-light leading-6 text-gray-900">
      {{ subtitle }}
    </legend>
  </div>

  <button
    *ngIf="showCloseButton"
    (click)="close()"
    type="button"
    class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
  >
    <span class="sr-only">Close</span>
    <svg
      class="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
  </button>
</div>
