import { trigger, transition, style, animate, state } from '@angular/animations';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  InjectionToken,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';

import { IModalConfig, ModalService } from '../modal.service';

export const DATA_TOKEN: InjectionToken<string> = new InjectionToken<string>('modal.data');

@Component({
  selector: 'app-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrl: './modal-container.component.scss',
  standalone: true,
  imports: [CommonModule],
  animations: [
    trigger('modalOverlay', [
      state('void', style({ opacity: 0 })),
      state('enter', style({ opacity: 1 })),
      state('leave', style({ opacity: 0 })),
      transition('void => enter', animate('300ms ease-out')),
      transition('enter => leave', animate('200ms ease-in')),
    ]),
    trigger('modalContent', [
      state('void', style({ transform: 'translateY(100%)' })),
      state('enter', style({ transform: 'translateY(0)' })),
      state('leave', style({ transform: 'translateY(100%)' })),
      transition('void => enter', animate('300ms ease-out')),
      transition('enter => leave', animate('200ms ease-in')),
    ]),
  ],
})
export class ModalContainerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() config?: IModalConfig;
  @Input() showModal: boolean = false;

  public injector?: Injector;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private modalService: ModalService,
  ) {}

  ngOnChanges(): void {
    this.injector = Injector.create({
      providers: [{ provide: DATA_TOKEN, useValue: this.config?.data }],
    });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('keydown', this.handleKeydown);
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      window.removeEventListener('keydown', this.handleKeydown);
    }
  }

  get state(): 'enter' | 'leave' {
    return this.showModal ? 'enter' : 'leave';
  }

  @HostListener('document:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    if (isPlatformBrowser(this.platformId)) {
      if (event.key === 'Escape') {
        this.modalService.clear();
      }
    }
  }
}
