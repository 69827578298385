import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MessagingService } from 'src/app/core/services/messaging/messaging.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { ModalService } from '../../modal.service';

import { ModalHeaderComponent } from '../../modal-header/modal-header.component';

import { ICandidateProfile } from 'src/app/candidate/models/candidate-profile.model';
import { IRecruiterProfile } from 'src/app/recruiter/models/recruiter-profile.model';

import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';
import { InputComponent } from '../../../input-groups/input/input.component';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  standalone: true,
  imports: [CommonModule, ModalHeaderComponent, InputComponent],
})
export class FeedbackComponent implements OnInit {
  public feedback: FormControl = new FormControl('', Validators.compose([Validators.required]));
  public isSubmitting: boolean = false;

  private isRecruiter: boolean = false;
  private isCandidate: boolean = false;
  private profile?: ICandidateProfile | IRecruiterProfile;

  constructor(
    private http: HttpClient,
    private router: Router,
    private messagingService: MessagingService,
    private toastService: ToastService,
    private modalService: ModalService,
  ) {}
  ngOnInit(): void {
    if (this.router.url.includes('candidate')) {
      this.isCandidate = true;
      this.messagingService.getCandidateProfile().subscribe((res) => {
        this.profile = res;
      });
    } else {
      this.isRecruiter = true;
      this.messagingService.getRecruiterProfile().subscribe((res) => {
        this.profile = res;
      });
    }
  }

  public postFeedback(): void {
    this.isSubmitting = true;
    this.http
      .post(environment.baseUrl + 'auth/feedback/', {
        feedback: this.feedback.value,
        ...(this.isRecruiter && { user: this.profile?.id }),
        ...(this.isCandidate && { candidate: this.profile?.id }),
      })
      .subscribe({
        next: () => {
          this.toastService.showSuccessToast('Feedback sent');
          this.modalService.clear();
        },
        error: () => {
          this.toastService.showSuccessToast('Please try again later.');
          this.modalService.clear();
        },
      });
  }
}
