<div class="main-container">
  <div class="login-container">
    <img class="-ml-2 h-auto w-60" src="./logo.png" />
    <div class="flex h-full w-full flex-col items-center justify-center gap-8">
      <button (click)="candidateLogin()" type="button" class="secondary-btn w-full">
        {{ "login as candidate" | uppercase }}
      </button>

      <button (click)="recruiterLogin()" type="button" class="primary-btn w-full">
        {{ "login as recruiter" | uppercase }}
      </button>
      <span class="self-start text-sm font-light text-black"
        >Need help?
        <a href="https://www.workstory.io/support/" class="text-blue-600">Contact support.</a></span
      >
    </div>
    <span class="self-start text-xs font-light text-black"
      >© 2023 WorkStory.io. All Rights Reserved.</span
    >
  </div>

  <div class="custom-gradient text-container">
    <span class="mb-[20px] block w-[60%] text-5xl font-medium text-white">{{
      "Create your worsktory" | uppercase
    }}</span>
    <p class="text-sm font-extralight text-white">
      Don't let your skills and talents go unnoticed! Take the first step towards your dream career
      by signing up for our platform today. With our user-friendly interface, you can easily create
      and share videos that highlight your unique abilities and catch the attention of potential
      recruiters.
    </p>

    <img class="m-auto h-auto max-h-[500px] w-auto" src="./landing-page.png" alt="" />
  </div>
</div>
