import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthService } from '../../services/auth/auth.service';
import { LocalstorageService } from '../../services/local-storage/local-storage.service';

import { STORAGE_ITEM } from '../../utils/local-storage.utils';
import { ROUTER_UTILS } from '../../utils/router.utils';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard {
  constructor(
    private router: Router,
    private localStorage: LocalstorageService,
    private authService: AuthService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.localStorage.getItem(STORAGE_ITEM.access_token)) {
      return true;
    } else {
      let role = this.authService.getUserRole();
      if (role == this.authService.roles.candidate) {
        this.router.navigate([
          ROUTER_UTILS.candidate.root,
          ROUTER_UTILS.candidate.dashboard.home.root,
        ]);
      } else {
        this.router.navigate([
          ROUTER_UTILS.recruiter.root,
          ROUTER_UTILS.recruiter.dashboard.home.root,
        ]);
      }
      return false;
    }
  }
}
