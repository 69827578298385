<div class="question-modal-container">
  <app-modal-header title="Give feedback" [showCloseButton]="true"></app-modal-header>
  <div class="qa-modal-body rounded-md">
    <p style="font-weight: 300; margin: 0px">Please give us your valuable feedback.</p>
    <app-input
      [submitted]="false"
      [largeText]="true"
      placeholder="Enter your feedback here..."
      [control]="feedback"
    ></app-input>

    <div class="mt-2 flex items-center justify-end">
      <button (click)="postFeedback()" [disabled]="feedback.invalid" class="primary-btn">
        <!-- <mat-icon *ngIf="isSubmitting"
          ><mat-spinner id="spinner" diameter="20"> </mat-spinner
        ></mat-icon> -->
        Send
      </button>
    </div>
  </div>
</div>
