import { take } from 'rxjs';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';

import { IServerVideoObj, VideoService } from 'src/app/candidate/services/video/video.service';
import { ModalService } from '../modal/modal.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';

import { DropdownComponent, IDropdownItem } from '../dropdown/dropdown.component';
import { IVideoPayload } from 'src/app/core/models/video-api-payloads/video.model';

import { encodeParam } from 'src/app/core/utils/paramEncoder';
import { TimeSincePipe } from '../../pipes/time-since.pipe';

import { constructShareVideoUrl, ROUTER_UTILS } from 'src/app/core/utils/router.utils';
import { IConfirmationPrompt } from '../modal/components/confirmation-prompt/confirmation-prompt.component';

@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrl: './video-card.component.scss',
  standalone: true,
  imports: [CommonModule, TimeSincePipe, DropdownComponent],
})
export class VideoCardComponent {
  @Input() item?: IVideoPayload;
  @Input() selectedVideos: IVideoPayload[] = [];
  @Input() selectMode: boolean = false;
  @Output() videosUpdated = new EventEmitter();
  @Output() selectEvent = new EventEmitter();

  public selected: boolean = false;
  public loading: boolean = true;

  private deleteVideoOption: IDropdownItem = { value: 'delete', display: 'Delete' };
  private addToPlaylistOption: IDropdownItem = {
    value: 'addToPlaylist',
    display: 'Add to playlist',
  };
  private shareOption: IDropdownItem = { value: 'share', display: 'Share' };
  private makePrivateOption: IDropdownItem = { value: 'makePrivate', display: 'Make private' };
  private makePublicOption: IDropdownItem = { value: 'makePublic', display: 'Make public' };
  private publicVideoOptions: IDropdownItem[] = [
    this.addToPlaylistOption,
    this.shareOption,
    this.makePrivateOption,
    this.deleteVideoOption,
  ];
  private privateVideoOptions: IDropdownItem[] = [
    this.addToPlaylistOption,
    this.makePublicOption,
    this.deleteVideoOption,
  ];
  public options: IDropdownItem[] = [];

  private prompt: IConfirmationPrompt = {
    heading: 'Delete Video',
    promptMessage: 'This action is permanent. Are you sure want to delete this video?',
    actionButtonText: 'Delete',
    action: 'delete',
  };

  constructor(
    private videoService: VideoService,
    private router: Router,
    private modalService: ModalService,
    private toastService: ToastService,
  ) {}

  ngOnChanges(): void {
    this.selected = this.selectedVideos.find((vid) => vid.id == this.item?.id) ? true : false;
    if (this.item?.public) {
      this.options = this.publicVideoOptions;
    } else {
      this.options = this.privateVideoOptions;
    }
  }

  public disableLoading(): void {
    this.loading = false;
  }

  private delete(): void {
    this.videoService.deleteVideo(this.item?.id as string).subscribe({
      next: () => {
        this.videosUpdated.emit();
        this.toastService.showToast('Video deleted succesfully!');
      },
    });
  }

  public routeToDetail(): void {
    if (!this.selectMode) {
      this.router.navigate([
        ROUTER_UTILS.candidate.root,
        ROUTER_UTILS.candidate.dashboard.videos.root,
        this.item?.id,
      ]);
    }
  }

  public select(): void {
    if (this.selectMode) {
      this.selectEvent.emit(this.item);
      this.selected = this.selectedVideos.find((vid) => vid.id == this.item?.id) ? true : false;
    } else this.routeToDetail();
  }

  public makePrivate(): void {
    let payload = {
      intro: this.item?.intro,
      public: false,
      description: this.item?.description,
      title: this.item?.title,
      tags: this.item?.tags,
    };

    this.videoService
      .updateVideoMetaData(payload as IServerVideoObj, this.item?.id as string)
      .subscribe((res) => {
        this.item = res;
        this.options = this.privateVideoOptions;
        this.toastService.showToast('Playlist is private now.');
      });
  }

  public makePublic(): void {
    let payload = {
      intro: this.item?.intro,
      public: true,
      description: this.item?.description,
      title: this.item?.title,
      tags: this.item?.tags,
    };

    this.videoService
      .updateVideoMetaData(payload as IServerVideoObj, this.item?.id as string)
      .subscribe((res) => {
        this.item = res;
        this.options = this.publicVideoOptions;
        this.toastService.showToast('Playlist is public now.');
      });
  }

  public addToPlaylist(): void {
    this.modalService.openAddToPlaylistModal(this.item?.id);
  }

  public share(): void {
    if (this.item?.public) {
      const noParamUrl = `${location.origin}/${constructShareVideoUrl()}`;
      const url = new URL(noParamUrl);
      url.searchParams.append('id', this.item.id);
      url.searchParams.append('key', this.item.key);
      this.modalService.openShareVideoModal(url.toString());
    }
  }

  public openDeleteConfirmationDialog(): void {
    this.modalService
      .openConfirmationModal(this.prompt)
      .pipe(take(1))
      .subscribe((res: boolean) => {
        if (res) {
          this.delete();
        }
      });
  }

  public handleOptionSelect(opt: IDropdownItem | undefined): void {
    switch (opt?.value) {
      case 'addToPlaylist':
        this.addToPlaylist();
        break;
      case 'share':
        this.share();
        break;
      case 'makePublic':
        this.makePublic();
        break;
      case 'makePrivate':
        this.makePrivate();
        break;
      case 'delete':
        this.openDeleteConfirmationDialog();
        break;
      default:
        console.warn('Unhandled option', opt);
        break;
    }
  }
}
