import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';

import { LoaderComponent } from '../loader/loader.component';

@Component({
  selector: 'app-video-player',
  standalone: true,
  imports: [CommonModule, LoaderComponent],
  templateUrl: './video-player.component.html',
  styleUrl: './video-player.component.scss',
})
export class VideoPlayerComponent implements OnChanges {
  @ViewChild('videoPlayer') videoPlayer?: ElementRef<HTMLVideoElement>;
  @Input() url?: string;
  public videoLoaded: boolean = false;

  public onLoadedData(): void {
    this.videoLoaded = true;
  }

  ngOnChanges(): void {
    if (this.videoPlayer && this.url) {
      this.videoLoaded = false; // Show loader until video loads
      this.videoPlayer.nativeElement.load(); // Manually reload the video
    }
  }
}
