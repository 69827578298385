import { Component } from '@angular/core';

import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-candidate',
  standalone: true,
  imports: [],
  templateUrl: './candidate.component.html',
})
export class CandidateCallbackComponent {
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.handleCandidateAuthCallback();
  }
}
