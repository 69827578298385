<div class="dialog-img flex-col">
  <app-modal-header title="Select attempt" [showCloseButton]="true"></app-modal-header>

  <div class="qa-video-body">
    <div class="playlist-select-videos-vertical-scroll-container flex gap-4">
      <div
        (click)="select(i)"
        class="playlist-select-video-card"
        [class.playlist-select-video-card-selected]="selectedInd == i"
        *ngFor="let answer of injectedData.answers; let i = index"
      >
        <div class="flex justify-between">
          <input
            [checked]="selectedInd == i"
            name="notification-method"
            type="radio"
            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
          />
        </div>

        <div style="padding-top: 12px">
          <video [src]="videoUrls[i]" style="width: 100%; border-radius: 4px" controls></video>
        </div>
      </div>
    </div>
    <div class="flex w-full items-center justify-end">
      <button (click)="confirm()" class="primary-btn">Confirm</button>
    </div>
  </div>
</div>
