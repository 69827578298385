<div class="w-[400px]">
  <app-modal-header [title]="data?.heading" [showCloseButton]="true"></app-modal-header>
  <div class="p-4">
    <ng-container *ngIf="!data?.isResumeDelete; else resumeDelete">
      <p class="text-sm">{{ data?.promptMessage }}</p>
    </ng-container>

    <ng-template #resumeDelete>
      <div class="resume-box-body">
        <p class="text-sm text-gray-600">{{ data?.promptMessage }}</p>
        <div>
          <span class="text-xs font-light text-gray-500"
            >This resume is associated with
            <ng-container *ngIf="data?.data.playlists.length"
              >{{ data?.data.playlists.length }}
              {{ data?.data.playlists.length > 1 ? "playlists" : "playlist" }}</ng-container
            >
            <ng-container *ngIf="data?.data.applications.length">
              &
              {{ data?.data.applications.length }}
              {{
                data?.data.applications.length > 1 ? "applications" : "application"
              }}</ng-container
            >
          </span>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="flex w-full items-center justify-end gap-4 p-4">
    <button (click)="close()" class="secondary-btn">Cancel</button>
    <button class="primary-btn {{ data?.action }}" (click)="onClick()">
      {{ data?.actionButtonText }}
    </button>
  </div>
</div>
