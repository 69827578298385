<div
  [style.visibility]="loading ? 'hidden' : 'visible'"
  class="video-tile shadow-md"
  [ngClass]="{ 'intro-video': item?.intro }"
  [ngClass]="{ 'active-video-selected': selected }"
  (click)="select()"
>
  <img
    (load)="disableLoading()"
    (click)="routeToDetail()"
    loading="lazy"
    class="video-img"
    [src]="item?.candidate_video?.thumbnail"
  />
  <div class="flex justify-between p-3">
    <p class="max-w-[70%] overflow-hidden text-ellipsis text-nowrap">
      {{ item?.title }}
    </p>
    <div>
      <app-dropdown
        *ngIf="!selectMode"
        (click)="$event.stopPropagation()"
        [items]="options"
        [isIcon]="true"
        (selectEvent)="handleOptionSelect($event)"
      ></app-dropdown>
    </div>
  </div>
  <div class="flex p-3">
    <p class="text-xs text-gray-500">
      {{ item?.created_dt | timeSince }}
    </p>
  </div>
</div>
