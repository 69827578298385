<div [class.drawer-bg]="drawerService.opened | async" class="drawer">
  <div class="drawer-content" *ngIf="drawerService.opened | async" [@slideInOut]>
    <div class="drawer-header">
      <span>{{ header }}</span>

      <button (click)="drawerService.opened.next(false)" type="button">
        <span class="sr-only">Dismiss</span>
        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path
            d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
          />
        </svg>
      </button>
    </div>
    <ng-container [ngTemplateOutlet]="template"></ng-container>
  </div>
</div>
