<div
  aria-live="assertive"
  class="pointer-events-none fixed inset-0 z-[9999] flex items-end px-4 py-6 sm:items-start sm:p-6"
>
  <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
    <div
      *ngIf="showToast"
      @fadeInOut
      [ngClass]="{
        'bg-green-50': config?.type === 'success',
        'bg-red-50': config?.type === 'error',
        'bg-white': config?.type === 'normal',
      }"
      class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
    >
      <div class="p-4">
        <div class="flex items-center">
          <div class="flex w-0 flex-1 justify-between">
            <p
              [ngClass]="{
                'text-red-800': config?.type === 'error',
                'text-gray-900': config?.type === 'normal',
                'text-green-800': config?.type === 'success',
              }"
              class="font-small pointer-events-none w-0 flex-1 text-sm text-gray-900"
            >
              {{ config?.message }}
            </p>
            <!-- <button
              type="button"
              class="ml-3 flex-shrink-0 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Undo
            </button> -->
          </div>
          <div class="ml-4 flex flex-shrink-0">
            <button
              type="button"
              [ngClass]="{
                'bg-red-50 text-red-800 hover:bg-red-100 focus:ring-red-800':
                  config?.type === 'error',
                'bg-white text-gray-400 hover:text-gray-500 focus:ring-indigo-500':
                  config?.type === 'normal',
                'bg-green-50 text-green-400 hover:text-green-500 focus:ring-green-500':
                  config?.type === 'success',
              }"
              class="inline-flex rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
              (click)="close()"
            >
              <span class="sr-only">Close</span>
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path
                  d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
