<div class="share-playlist-container">
  <app-modal-header
    title="Manage who can view this playlist"
    subtitle="You can share it with individuals using their email or share it with everyone!"
    [showCloseButton]="true"
  ></app-modal-header>

  <div *ngIf="isShared" class="share-playlist-body !pb-0">
    <div class="is-shared rounded-md border-[1px] border-indigo-400 bg-indigo-50">
      <div style="display: flex; flex-direction: row; align-items: center; gap: 4px">
        <span class="relative flex h-3 w-3">
          <span
            class="absolute inline-flex h-full w-full animate-ping rounded-full bg-indigo-400 opacity-75"
          ></span>
          <span class="relative inline-flex h-3 w-3 rounded-full bg-indigo-500"></span>
        </span>
        <span class="ml-2 text-sm text-indigo-700"
          >This playlist is shared
          {{
            injectedData.playlist.share_status?.mode === "public"
              ? "publicly."
              : "with select emails."
          }}</span
        >
      </div>

      <button (click)="stopSharing()" class="secondary-btn flex h-[30px] items-center text-xs">
        Stop sharing
      </button>
    </div>
  </div>
  <div class="share-playlist-body">
    <div class="flex items-center justify-between gap-2">
      <div
        (click)="shareModeControl.setValue('public')"
        class="relative flex flex-1 cursor-pointer flex-col rounded-md border p-2 shadow-sm"
        [ngClass]="{
          'z-10 border-indigo-200 bg-indigo-50': shareModeControl.value === 'public',
          'border-gray-200 bg-white': shareModeControl.value !== 'public',
        }"
      >
        <span class="flex items-center text-sm">
          <input
            type="radio"
            name="pricing-plan"
            value="public"
            [checked]="shareModeControl.value === 'public'"
            class="h-3 w-3 border-gray-300 text-indigo-600 focus:ring-indigo-600 active:ring-2 active:ring-indigo-600 active:ring-offset-2"
          />
          <span
            [class.text-indigo-900]="shareModeControl.value === 'public'"
            [class.text-gray-900]="shareModeControl.value !== 'public'"
            class="ml-3 font-normal"
          >
            Share with everyone</span
          >
        </span>
      </div>
      <div
        (click)="shareModeControl.setValue('select')"
        class="relative flex flex-1 cursor-pointer flex-col rounded-md border p-2 shadow-sm"
        [ngClass]="{
          'z-10 border-indigo-200 bg-indigo-50': shareModeControl.value === 'select',
          'border-gray-200 bg-white': shareModeControl.value !== 'select',
        }"
      >
        <span class="flex items-center text-sm">
          <input
            type="radio"
            name="pricing-plan"
            value="select"
            [checked]="shareModeControl.value === 'select'"
            class="h-3 w-3 border-gray-300 text-indigo-600 focus:ring-indigo-600 active:ring-2 active:ring-indigo-600 active:ring-offset-2"
          />
          <span
            [class.text-indigo-900]="shareModeControl.value === 'select'"
            [class.text-gray-900]="shareModeControl.value !== 'select'"
            class="ml-3 font-normal"
          >
            Select emails</span
          >
        </span>
      </div>
    </div>

    <div class="share-playlist-content">
      <ng-container
        [ngTemplateOutlet]="shareModeControl.value === 'public' ? public : select"
      ></ng-container>
      <br />
    </div>
  </div>
  <div
    *ngIf="shareModeControl.value === 'public'; else selectFooter"
    class="flex items-center justify-between gap-4 border-t-[1px] border-gray-200 p-4"
  >
    <app-input
      class="mt-[-13px] w-full"
      [whiteBackground]="false"
      [largeText]="false"
      [control]="linkControl"
      placeholder=""
    ></app-input>
    <button
      *ngIf="!linkControl.value"
      (click)="share()"
      class="primary-btn flex h-[36px] items-center justify-between gap-2"
    >
      <span> Generate </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        class="size-4"
      >
        <path
          fill-rule="evenodd"
          d="M19.902 4.098a3.75 3.75 0 0 0-5.304 0l-4.5 4.5a3.75 3.75 0 0 0 1.035 6.037.75.75 0 0 1-.646 1.353 5.25 5.25 0 0 1-1.449-8.45l4.5-4.5a5.25 5.25 0 1 1 7.424 7.424l-1.757 1.757a.75.75 0 1 1-1.06-1.06l1.757-1.757a3.75 3.75 0 0 0 0-5.304Zm-7.389 4.267a.75.75 0 0 1 1-.353 5.25 5.25 0 0 1 1.449 8.45l-4.5 4.5a5.25 5.25 0 1 1-7.424-7.424l1.757-1.757a.75.75 0 1 1 1.06 1.06l-1.757 1.757a3.75 3.75 0 1 0 5.304 5.304l4.5-4.5a3.75 3.75 0 0 0-1.035-6.037.75.75 0 0 1-.354-1Z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
    <button
      *ngIf="linkControl.value"
      (click)="copyLink()"
      class="secondary-btn flex h-[36px] items-center justify-between gap-2"
    >
      <span class="text-nowrap"> Copy link </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        class="size-4"
      >
        <path
          fill-rule="evenodd"
          d="M10.5 3A1.501 1.501 0 0 0 9 4.5h6A1.5 1.5 0 0 0 13.5 3h-3Zm-2.693.178A3 3 0 0 1 10.5 1.5h3a3 3 0 0 1 2.694 1.678c.497.042.992.092 1.486.15 1.497.173 2.57 1.46 2.57 2.929V19.5a3 3 0 0 1-3 3H6.75a3 3 0 0 1-3-3V6.257c0-1.47 1.073-2.756 2.57-2.93.493-.057.989-.107 1.487-.15Z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
  </div>
</div>

<ng-template #select>
  <div class="select-share-mode pt-2">
    <div class="flex items-center gap-4">
      <app-input
        class="mt-[-12.5px] w-full"
        [whiteBackground]="false"
        [largeText]="false"
        placeholder="Enter email"
        [control]="emailControl"
      ></app-input>
      <button
        [disabled]="!emailControl.valid"
        (click)="addEmail()"
        class="secondary-btn flex h-[37px] items-center"
      >
        Add
      </button>
    </div>
    <!-- <mat-divider style="margin-bottom: 8px"></mat-divider> -->
    <ng-container *ngIf="sharedEmails.length; else noEmails">
      <span class="text-content">Shared with</span>
      <div class="shared-list-playlist">
        <div class="shared-email-content shadow-md" *ngFor="let email of sharedEmails">
          <span class="text-sm font-light">{{ email }}</span>
          <button
            (click)="revoke(email)"
            style="line-height: normal !important"
            class="secondary-btn flex h-[25px] items-center justify-center text-xs"
          >
            Revoke
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #noEmails>
      <div class="share-playlist-empty-state-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="size-5"
        >
          <path
            d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z"
          />
          <path
            d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z"
          />
        </svg>

        <span class="text-sm font-light text-gray-500">Add emails to share this playlist to.</span>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #public>
  <div class="select-share-mode">
    <!-- <span class="text-content"
      >Everyone will be able to see all to see the videos and resume attached to this
      playlist.</span
    > -->

    <div class="rounded-md bg-yellow-50 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg
            class="h-5 w-5 text-yellow-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-yellow-800">This playlist will be public</h3>
          <div class="mt-2 text-sm text-yellow-700">
            <p>
              Everyone will be able to see all to see the videos and resume attached to this
              playlist.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #selectFooter>
  <div class="share-playlist-footer">
    <button
      (click)="share()"
      class="primary-btn ml-auto flex items-center justify-between gap-2"
      [disabled]="!sharedEmails.length"
    >
      <span> Share playlist </span>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        class="size-4"
      >
        <path
          fill-rule="evenodd"
          d="M19.902 4.098a3.75 3.75 0 0 0-5.304 0l-4.5 4.5a3.75 3.75 0 0 0 1.035 6.037.75.75 0 0 1-.646 1.353 5.25 5.25 0 0 1-1.449-8.45l4.5-4.5a5.25 5.25 0 1 1 7.424 7.424l-1.757 1.757a.75.75 0 1 1-1.06-1.06l1.757-1.757a3.75 3.75 0 0 0 0-5.304Zm-7.389 4.267a.75.75 0 0 1 1-.353 5.25 5.25 0 0 1 1.449 8.45l-4.5 4.5a5.25 5.25 0 1 1-7.424-7.424l1.757-1.757a.75.75 0 1 1 1.06 1.06l-1.757 1.757a3.75 3.75 0 1 0 5.304 5.304l4.5-4.5a3.75 3.75 0 0 0-1.035-6.037.75.75 0 0 1-.354-1Z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
  </div>
</ng-template>
