import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';

import { CloneHeaderService } from './clone-header.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private cloneHeaderService: CloneHeaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.cloneHeaderService
      .cloneHeader(request)
      .pipe(switchMap((clonedRequest: HttpRequest<unknown>) => next.handle(clonedRequest)));
  }
}
