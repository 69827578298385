import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NoAuthGuard } from './core/guards/no-auth-guard/no-auth.guard';
import { AuthGuard } from './core/guards/auth-guard/auth.guard';

import { LandingPageComponent } from './landing-page/landing-page.component';
import { CandidateCallbackComponent } from './core/auth-callbacks/candidate/candidate.component';
import { RecruiterCallbackComponent } from './core/auth-callbacks/recruiter/recruiter.component';

import { ROUTER_UTILS } from './core/utils/router.utils';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: ROUTER_UTILS.candidate.root,
    loadChildren: () => import('./candidate/candidate.module').then((m) => m.CandidateModule),
    canActivate: [AuthGuard],
  },
  {
    path: ROUTER_UTILS.recruiter.root,
    loadChildren: () => import('./recruiter/recruiter.module').then((m) => m.RecruiterModule),
    canActivate: [AuthGuard],
  },
  {
    path: ROUTER_UTILS.public.root,
    loadChildren: () => import('./public/public.module').then((m) => m.PublicModule),
  },
  {
    path: ROUTER_UTILS.auth0Callback.candidateCallback,
    component: CandidateCallbackComponent,
  },
  {
    path: ROUTER_UTILS.auth0Callback.recruiterCallback,
    component: RecruiterCallbackComponent,
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
