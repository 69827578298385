import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IApplicant, ICandidateProfile } from 'src/app/candidate/models/candidate-profile.model';
import { IPaginatedResponse } from 'src/app/core/models/paginated-payload.model';
import {
  IApplication,
  IJobApplication,
  IMyApplicant,
} from 'src/app/recruiter/models/application.model';

import { environment } from 'src/environments/environment';

export interface IInviteApplicant {
  emails: string[];
  message: string;
  job: string;
  jobId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationManagerService {
  private readonly applicationApiBaseUrl: string = `${environment.baseUrl}application/api/`;

  constructor(private readonly http: HttpClient) {}

  /**
   * Get job applicants by job ID, optionally filtered by a parameter.
   * @param id - Job ID
   * @param param - Optional filter parameter
   * @returns Observable of IJobApplication
   */
  public getJobApplicants(id: string, param?: string): Observable<IJobApplication> {
    if (param) {
      let params = new HttpParams().append('type', param);
      return this.http.get<IJobApplication>(`${this.applicationApiBaseUrl}job-applicants/${id}`, {
        params,
      });
    } else {
      return this.http.get<IJobApplication>(`${this.applicationApiBaseUrl}job-applicants/${id}`);
    }
  }

  /**
   * Invite applicants via email.
   * @param payload - Invitation details
   * @returns Observable of any
   */
  public inviteApplications(payload: IInviteApplicant): Observable<void> {
    return this.http.post<void>(`${this.applicationApiBaseUrl}applicants/invite/`, payload);
  }

  /**
   * Get all job applicants.
   * @returns Observable of IPaginatedResponse containing IApplicant
   */
  public getAllJobApplicants(): Observable<IPaginatedResponse<IMyApplicant>> {
    return this.http.get<IPaginatedResponse<IMyApplicant>>(
      `${this.applicationApiBaseUrl}job-applicants/all`,
    );
  }

  /**
   * Ask a question to applicants.
   * @param payload - Question payload
   * @returns Observable of IPaginatedResponse containing IJobApplication
   */
  public askQuestion(payload: object): Observable<IPaginatedResponse<IJobApplication>> {
    return this.http.post<IPaginatedResponse<IJobApplication>>(
      `${this.applicationApiBaseUrl}job-applicants/question/`,
      payload,
    );
  }

  /**
   * Get list of jobs.
   * @returns Observable of IPaginatedResponse containing IJobApplication
   */
  public getJobs(): Observable<IPaginatedResponse<IJobApplication>> {
    return this.http.get<IPaginatedResponse<IJobApplication>>(
      `${this.applicationApiBaseUrl}job-applicants/`,
    );
  }

  /**
   * Get paginated applicants based on URL.
   * @param url - Pagination URL
   * @returns Observable of IPaginatedResponse containing IMyApplicant
   */
  public applicantPaginator(url: string): Observable<IPaginatedResponse<IMyApplicant>> {
    return this.http.get<IPaginatedResponse<IMyApplicant>>(url);
  }

  /**
   * Get applicant profile by ID.
   * @param id - Applicant ID
   * @returns Observable of ICandidateProfile
   */
  public getApplicantProfile(id: string): Observable<ICandidateProfile> {
    return this.http.get<ICandidateProfile>(`${this.applicationApiBaseUrl}applicants/${id}`);
  }

  /**
   * Get job application by applicant ID.
   * @param id - Applicant ID
   * @returns Observable of IApplication
   */
  public getJobApplicant(id: string): Observable<IApplication> {
    return this.http.get<IApplication>(`${this.applicationApiBaseUrl}applicants/${id}`);
  }

  /**
   * Get paginated job applications based on URL.
   * @param url - Pagination URL
   * @returns Observable of IPaginatedResponse containing IApplication
   */
  public appPaginator(url: string): Observable<IPaginatedResponse<IApplication>> {
    return this.http.get<IPaginatedResponse<IApplication>>(url);
  }

  /**
   * Archive a job applicant by ID.
   * @param id - Applicant ID
   * @returns Observable of any
   */
  public archive(id: string): Observable<void> {
    const payload = { id };
    return this.http.post<void>(`${this.applicationApiBaseUrl}job-applicants/archive/`, payload);
  }

  /**
   * Mark a job applicant as seen by ID.
   * @param id - Applicant ID
   * @returns Observable of any
   */
  public seen(id: string): Observable<void> {
    const payload = { id };
    return this.http.post<void>(`${this.applicationApiBaseUrl}job-applicants/seen/`, payload);
  }

  /**
   * Shortlist a job applicant by ID.
   * @param id - Applicant ID
   * @returns Observable of any
   */
  public shortlist(id: string): Observable<void> {
    const payload = { id };
    return this.http.post<void>(`${this.applicationApiBaseUrl}job-applicants/shortlist/`, payload);
  }
}
