<div class="playlist-detail-container">
  <app-modal-header title="Select videos" [showCloseButton]="true"></app-modal-header>

  <div class="add-video-to-playlist-body">
    <app-input [control]="searchControl" placeholder="search"></app-input>

    <div class="add-video-to-playlist-content">
      @if (!loading) {
        <div class="w-[800px] max-w-[800px]">
          <div
            id="scroller"
            appScrollTracker
            (scrolled)="getMoreVideos()"
            class="flex gap-2 overflow-scroll"
          >
            <div *ngFor="let video of videos" class="min-w-[300px] max-w-[300px] p-1">
              <app-video-card
                [selectedVideos]="selectedVideos"
                [selectMode]="true"
                (selectEvent)="selectVideo($event)"
                [item]="video"
              ></app-video-card>
            </div>
          </div>
          <div class="flex gap-4 overflow-scroll pt-4">
            <div
              *ngFor="let vid of selectedVideos"
              class="inline-flex items-center gap-2 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
            >
              <span class="text-nowrap"> {{ vid.title }}</span>
              <button
                (click)="selectVideo(vid)"
                type="button"
                class="p-.5 inline-flex rounded-md bg-gray-50 text-gray-500 hover:bg-gray-200"
              >
                <span class="sr-only">Dismiss</span>
                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path
                    d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      } @else {
        <div class="flex h-[300px] w-[400px] items-center justify-center">
          <app-loader></app-loader>
        </div>
      }
    </div>

    <br />
    <div class="flex items-center justify-end gap-4">
      <button class="secondary-btn" (click)="cancel()">Cancel</button>
      <button class="primary-btn" (click)="save()">Save</button>
    </div>
  </div>
</div>
