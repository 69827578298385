import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';

import { ModalService } from '../../modal.service';

import { DropdownComponent, IDropdownItem } from '../../../dropdown/dropdown.component';
import { ModalHeaderComponent } from '../../modal-header/modal-header.component';
import { InputComponent } from '../../../input-groups/input/input.component';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  standalone: true,
  imports: [ModalHeaderComponent, InputComponent, DropdownComponent, ReactiveFormsModule],
})
export class QuestionComponent {
  questionForm = new FormGroup({
    question: new FormControl('', Validators.compose([Validators.required])),
    prep_time: new FormControl('', Validators.compose([Validators.required])),
    num_tries_max: new FormControl('', Validators.compose([Validators.required])),
    response_time_limit: new FormControl('', Validators.compose([Validators.required])),
  });
  submitted = false;

  prepTimeOpts: IDropdownItem[] = [
    { display: '2 minutes', value: 2 },
    { display: '3 minutes', value: 3 },
    { display: '5 minutes', value: 5 },
  ];

  attempsOpts: IDropdownItem[] = [
    { display: '2 attempts', value: 2 },
    { display: '3 attempts', value: 3 },
    { display: '5 attempts', value: 5 },
  ];

  responseTimeOpts: IDropdownItem[] = [
    { display: '2 minutes', value: 2 },
    { display: '3 minutes', value: 3 },
    { display: '5 minutes', value: 5 },
  ];

  constructor(private modalService: ModalService) {}

  public closeDialog(): void {
    this.modalService.clear();
  }

  public submit(): void {
    this.submitted = true;

    if (this.questionForm.valid) {
      this.modalService.clear(this.questionForm.value);
    }
  }

  public get questionControl(): FormControl {
    return this.questionForm.controls.question as FormControl;
  }

  public get prepTimeControl(): FormControl {
    return this.questionForm.controls.prep_time as FormControl;
  }

  public get responseTimeControl(): FormControl {
    return this.questionForm.controls.response_time_limit as FormControl;
  }

  public get attemptsControl(): FormControl {
    return this.questionForm.controls.num_tries_max as FormControl;
  }
}
