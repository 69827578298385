import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { AuthService } from "../core/services/auth/auth.service";

import { ROUTER_UTILS } from "../core/utils/router.utils";

@Component({
  selector: "app-landing-page",
  imports: [CommonModule],
  standalone: true,
  templateUrl: "./landing-page.component.html",
  styleUrl: "./landing-page.component.scss",
})
export class LandingPageComponent {
  private redirectUri: string = "/";
  private isCandidateUri: boolean = false;
  private isRecruiterUri: boolean = false;

  private candidateDefaultRedirect: string = `/${ROUTER_UTILS.candidate.root}/${ROUTER_UTILS.candidate.dashboard.home.root}`;
  private recruiterDefaultRedirect: string = `/${ROUTER_UTILS.recruiter.root}/${ROUTER_UTILS.candidate.dashboard.home.root}`;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params["redirectUrl"]) {
        this.redirectUri = decodeURIComponent(params["redirectUrl"]);
        if (this.redirectUri.includes("candidate")) {
          this.isCandidateUri = true;
        } else {
          this.isRecruiterUri = true;
        }
      }
    });
  }

  public candidateLogin(): void {
    this.authService.candidateLogin(
      this.isCandidateUri ? this.redirectUri : this.candidateDefaultRedirect,
    );
  }

  public recruiterLogin(): void {
    this.authService.recruiterLogin(
      this.isRecruiterUri ? this.redirectUri : this.recruiterDefaultRedirect,
    );
  }
}
