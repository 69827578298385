import { BehaviorSubject } from 'rxjs';

import { isPlatformBrowser } from '@angular/common';
import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';

import { ServerErrorInterceptor } from './core/interceptors/server-error.interceptor';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';

import { AppComponent } from './app.component';
import { ToastComponent } from './core/services/toast/component/toast.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { ModalContainerComponent } from './shared/components/modal/modal-container/modal-container.component';
import { DrawerComponent } from './shared/components/drawer/component/drawer.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ToastComponent,
    NotFoundComponent,
    ModalContainerComponent,
    DrawerComponent,
  ],
  providers: [
    provideClientHydration(),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  static isBrowser: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    AppModule.isBrowser.next(isPlatformBrowser(this.platformId));
  }
}
