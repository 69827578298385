import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { DATA_TOKEN } from '../../modal-container/modal-container.component';
import { ModalHeaderComponent } from '../../modal-header/modal-header.component';
import { ModalService } from '../../modal.service';

export interface IConfirmationPrompt {
  heading: string;
  promptMessage: string;
  actionButtonText: string;
  action: string;
  data?: any;
  isResumeDelete?: boolean;
}

@Component({
  selector: 'app-confirmation-prompt',
  templateUrl: './confirmation-prompt.component.html',
  styleUrls: ['./confirmation-prompt.component.scss'],
  standalone: true,
  imports: [CommonModule, ModalHeaderComponent],
})
export class ConfirmationPromptComponent implements OnInit {
  public data?: IConfirmationPrompt;

  constructor(
    @Inject(DATA_TOKEN) private injectedData: { ['prompt']: IConfirmationPrompt },
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.data = this.injectedData.prompt;
  }

  public close(): void {
    this.modalService.clear();
  }

  onClick() {
    this.modalService.clear(true);
  }
}
