<div class="w-[500px]">
  <app-modal-header title="You will be logged out soon" [showCloseButton]="true">
  </app-modal-header>

  <div class="flex flex-col gap-4 p-4">
    <p class="text-sm font-light">
      You seem to be inactive, we automatically log you out if you have been inactive for a certain
      period of time.
    </p>

    <countdown
      class="text-center text-lg font-semibold text-black"
      #timer
      [config]="countdownConfig"
    ></countdown>

    <button (click)="cancel()" class="primary-btn ml-auto">Continue session</button>
  </div>
</div>
