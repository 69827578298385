import { BehaviorSubject, combineLatest, from, Observable, throwError } from 'rxjs';
import { catchError, concatMap, shareReplay, tap } from 'rxjs/operators';
import { Auth0Client, createAuth0Client, RedirectLoginResult } from '@auth0/auth0-spa-js';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { environment } from 'src/environments/environment';

import { MessagingService } from '../messaging/messaging.service';
import { LocalstorageService } from '../local-storage/local-storage.service';

import { TokenPayload } from '../../models/authentication-payloads/token-payload.model';
import { LoginPayload } from '../../models/authentication-payloads/login-payload.model';

import { STORAGE_ITEM } from '../../utils/local-storage.utils';
import { ROUTER_UTILS } from '../../utils/router.utils';
import { decodeParam, encodeParam } from '../../utils/paramEncoder';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userApiBaseUrl: string = environment.baseUrl + 'auth/';
  private candidateRefreshTokenUrl: string = environment.baseUrl + 'api/token/refreshCandidate/';
  private recruiterRefreshTokenUrl: string = environment.baseUrl + 'api/token/refreshRecruiter/';
  private candidateAuth0CallBackUrl: string = `${this.document.location.origin}/${ROUTER_UTILS.auth0Callback.candidateCallback}`;
  private recruiterAuth0CallBackUrl: string = `${this.document.location.origin}/${ROUTER_UTILS.auth0Callback.recruiterCallback}`;
  private auth0Domain: string = environment.auth0Domain;
  private auth0ClientId: string = environment.auth0ClientId;
  private auth0Client$: Observable<Auth0Client> = (
    from(
      createAuth0Client({
        domain: this.auth0Domain,
        clientId: this.auth0ClientId,
      }),
    ) as Observable<Auth0Client>
  ).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError((err) => throwError(() => new Error(err))),
  );

  public roles = {
    recruiter: 'recruiter',
    candidate: 'candidate',
  };
  public isAuthenticated$: Observable<boolean> = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
  );
  private handleRedirectCallback$: Observable<RedirectLoginResult<any>> = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback())),
  );
  private userProfileSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public userProfile$ = this.userProfileSubject$.asObservable();
  public loggedIn: boolean | null = null;

  private getTokenSilently$: Observable<string> = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.getTokenSilently())),
  );

  private accessTokenSubject$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
    null,
  );
  public accessToken$: Observable<string | null> = this.accessTokenSubject$.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private localStorage: LocalstorageService,
    private messagingService: MessagingService,
  ) {}

  private getUser$(): Observable<any> {
    return this.auth0Client$.pipe(
      // tap((options) => console.debug('get User',options)),
      concatMap((client: Auth0Client) => from(client.getUser())),
    );
  }

  public candidateLogin(redirectPath: string = '/'): void {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists

    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.loginWithRedirect({
        authorizationParams: { redirect_uri: this.candidateAuth0CallBackUrl },
        appState: { target: redirectPath },
      });
    });
  }

  public recruiterLogin(redirectPath: string = '/'): void {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists

    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.loginWithRedirect({
        authorizationParams: { redirect_uri: this.recruiterAuth0CallBackUrl },
        appState: { target: redirectPath },
      });
    });
  }

  public handleRecruiterAuthCallback(): void {
    // Only the callback component should call this method
    // Call when app reloads after user logs in with Auth0
    let targetRoute: string; // Path to redirect to after login processsed
    // Ensure Auth0 client instance exists
    const authComplete$ = this.auth0Client$.pipe(
      // Have client, now call method to handle auth callback redirect
      concatMap(() => this.handleRedirectCallback$),
      tap((cbRes) => {
        // Get and set target redirect route from callback results
        targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
      }),
      concatMap(() => {
        // Redirect callback complete; create stream returning
        // user data, token, and authentication status
        return combineLatest([this.getUser$(), this.getTokenSilently$, this.isAuthenticated$]);
      }),
    );
    // Subscribe to authentication completion observable
    // Response will be an array of user, token, and login status
    authComplete$.subscribe(([user, token, loggedIn]) => {
      // Update subjects and loggedIn property

      this.userProfileSubject$.next(user);
      this.accessTokenSubject$.next(token);
      this.localStorage.setItem(STORAGE_ITEM.auth0_token, token);

      this.loggedIn = loggedIn;

      if (!this.localStorage.getItem(STORAGE_ITEM.access_token)) {
        let payload = {
          username: user?.sub,
          email: user?.email,
          access_token: token,
          role: this.roles.recruiter,
        };
        this.isUserRegistered(payload).subscribe({
          next: (res) => {
            this.messagingService.emitRecruiterProfile(res);
            this.localStorage.setItem(STORAGE_ITEM.access_token, res.access_token);
            this.localStorage.setItem(STORAGE_ITEM.refresh_token, res.refresh_token);

            this.router.navigateByUrl(targetRoute);
          },
          error: (err) => {
            this.logout();
            console.log(err);
          },
        });
      } else {
        this.router.navigateByUrl(targetRoute);
      }
    });
  }

  public handleCandidateAuthCallback(): void {
    // Only the callback component should call this method
    // Call when app reloads after user logs in with Auth0
    let targetRoute: string; // Path to redirect to after login processsed
    // Ensure Auth0 client instance exists
    const authComplete$ = this.auth0Client$.pipe(
      // Have client, now call method to handle auth callback redirect
      concatMap(() => this.handleRedirectCallback$),
      tap((cbRes) => {
        // Get and set target redirect route from callback results
        targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
      }),
      concatMap(() => {
        // Redirect callback complete; create stream returning
        // user data, token, and authentication status
        return combineLatest([this.getUser$(), this.getTokenSilently$, this.isAuthenticated$]);
      }),
    );
    // Subscribe to authentication completion observable
    // Response will be an array of user, token, and login status
    authComplete$.subscribe(([user, token, loggedIn]) => {
      // Update subjects and loggedIn property
      this.userProfileSubject$.next(user);
      this.accessTokenSubject$.next(token);
      this.localStorage.setItem(STORAGE_ITEM.auth0_token, token);

      this.loggedIn = loggedIn;

      if (!this.localStorage.getItem(STORAGE_ITEM.access_token)) {
        let payload = {
          username: user?.sub,
          email: user?.email,
          access_token: token,
          role: this.roles.candidate,
        };
        this.isUserRegistered(payload).subscribe({
          next: (res) => {
            this.messagingService.emitCandidateProfile(res);
            this.localStorage.setItem(STORAGE_ITEM.access_token, res.access_token);
            this.localStorage.setItem(STORAGE_ITEM.refresh_token, res.refresh_token);
            if (targetRoute.includes('/public/job')) {
              this.router.navigate(
                [
                  ROUTER_UTILS.candidate.root,
                  ROUTER_UTILS.candidate.dashboard.jobs.root,
                  ROUTER_UTILS.candidate.dashboard.jobs.jobDetail,
                ],
                {
                  queryParams: {
                    id: encodeParam(JSON.parse(decodeParam(targetRoute.split('?meta=')[1]))['id']),
                  },
                },
              );
            } else {
              this.router.navigateByUrl(targetRoute);
            }
          },
          error: (err) => {
            this.logout();
            console.log(err);
          },
        });
      } else {
        if (targetRoute.includes('/public/job')) {
          this.router.navigate(
            [
              ROUTER_UTILS.candidate.root,
              ROUTER_UTILS.candidate.dashboard.jobs.root,
              ROUTER_UTILS.candidate.dashboard.jobs.jobDetail,
            ],
            {
              queryParams: {
                id: encodeParam(JSON.parse(decodeParam(targetRoute.split('?meta=')[1]))['id']),
              },
            },
          );
        } else {
          this.router.navigateByUrl(targetRoute);
        }
      }
    });
  }

  public logout(useRedirection: boolean = false): void {
    let lang = this.localStorage.getItem(STORAGE_ITEM.langauge);
    this.localStorage.clear();
    this.localStorage.setItem(STORAGE_ITEM.langauge, lang as string);
    let redirectPath: string;
    if (useRedirection) {
      redirectPath = `${this.document.location.origin}/${
        ROUTER_UTILS.landingPage.root
      }?redirectUrl=${encodeURIComponent(this.router.url)}`;
    } else {
      redirectPath = `${this.document.location.origin}/${ROUTER_UTILS.landingPage.root}`;
    }
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        clientId: this.auth0ClientId,
        logoutParams: {
          returnTo: redirectPath,
        },
      });
    });
  }

  public isUserRegistered(payload: Object): Observable<LoginPayload> {
    return this.http.post<LoginPayload>(this.userApiBaseUrl + 'login/', payload);
  }

  public refreshToken(payload: any): Observable<TokenPayload> {
    let access_token = payload['access'];
    let role = JSON.parse(atob(access_token.split('.')[1]))['role'];
    if (role == this.roles.candidate) {
      return this.http.post<TokenPayload>(this.candidateRefreshTokenUrl, payload);
    } else {
      return this.http.post<TokenPayload>(this.recruiterRefreshTokenUrl, payload);
    }
  }

  public getUserRole(): 'recruiter' | 'candidate' | null {
    let access_token = this.localStorage.getItem(STORAGE_ITEM.access_token) as string;
    if (access_token) return JSON.parse(atob(access_token.split('.')[1]))['role'];
    return null;
  }
}
