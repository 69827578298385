<div class="w-[400px]">
  <app-modal-header
    title="Add video to playlist"
    [showCloseButton]="true"
    subtitle="Select which playlists should have this video"
  ></app-modal-header>
  @if (!loading) {
    <div class="flex flex-col items-center gap-4 pb-4">
      <div class="max-h-[400px] w-full overflow-scroll bg-gray-100">
        <div class="flex flex-col gap-4 p-4">
          <ng-container *ngFor="let playlist of playlists">
            <div class="relative flex items-start rounded-md bg-white p-4 shadow-md">
              <div class="min-w-0 flex-1 text-sm leading-6">
                <label for="candidates" class="font-medium text-gray-900">{{
                  playlist.title
                }}</label>
                <p id="comments-description" class="text-gray-500">
                  This playlist has
                  <span class="text-black"
                    >{{ playlist.count }} {{ playlist.count === 1 ? "video" : "videos" }}</span
                  >
                  and is <span class="text-black">{{ playlist.public ? "public" : "private" }}</span
                  >.
                </p>
              </div>
              <div class="ml-3 flex h-6 items-center">
                <input
                  #checkbox
                  (change)="handleCheck(playlist, checkbox.checked)"
                  [checked]="checkIfVideoInPlaylist(playlist)"
                  id="candidates"
                  aria-describedby="candidates-description"
                  name="candidates"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <button (click)="newPlaylist()" class="primary-btn flex items-center justify-between gap-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-4"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>

        Create new playlist
      </button>
    </div>
  } @else {
    <div class="p-8">
      <app-loader></app-loader>
    </div>
  }
</div>
