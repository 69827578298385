import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';

import { VideoPlayerComponent } from '../../../video-player/video-player.component';

import { DATA_TOKEN } from '../../modal-container/modal-container.component';
import { ModalHeaderComponent } from '../../modal-header/modal-header.component';
import { IVideoPayload } from 'src/app/core/models/video-api-payloads/video.model';

@Component({
  selector: 'app-modal-video-player',
  standalone: true,
  imports: [CommonModule, VideoPlayerComponent, ModalHeaderComponent],
  templateUrl: './modal-video-player.component.html',
  styleUrl: './modal-video-player.component.scss',
})
export class ModalVideoPlayerComponent {
  constructor(@Inject(DATA_TOKEN) public injectedData: { ['video']: IVideoPayload }) {}
}
