import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputComponent } from '../../../input-groups/input/input.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';

import { VideoService } from 'src/app/candidate/services/video/video.service';
import { ModalService } from '../../modal.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';

import { ValidateUrl } from 'src/app/core/utils/form.utils';
import { ModalHeaderComponent } from '../../modal-header/modal-header.component';

import { IVideoPlaylist } from 'src/app/core/models/video-api-payloads/video-playlist.model';

import { encodeParam } from 'src/app/core/utils/paramEncoder';
import { constructSharePlaylistUrl } from 'src/app/core/utils/router.utils';
import { DATA_TOKEN } from '../../modal-container/modal-container.component';

@Component({
  selector: 'app-share-playlist',
  templateUrl: './share-playlist.component.html',
  styleUrls: ['./share-playlist.component.scss'],
  standalone: true,
  imports: [CommonModule, InputComponent, ReactiveFormsModule, ModalHeaderComponent],
})
export class SharePlaylistComponent implements OnInit {
  public shareModeControl: FormControl = new FormControl('select');
  public emailControl: FormControl = new FormControl(
    '',
    Validators.compose([Validators.required, ValidateUrl]),
  );
  public linkControl: FormControl = new FormControl('');

  public isShared: boolean = false;
  public sharedEmails: string[] = [];

  constructor(
    private videoService: VideoService,
    @Inject(DATA_TOKEN) public injectedData: { ['playlist']: IVideoPlaylist },
    private toastService: ToastService,
    private clipboard: Clipboard,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    if (this.injectedData.playlist.share_status) {
      if (this.injectedData.playlist.share_status.mode === 'public') {
        this.linkControl.setValue(this.getLink());
      }
      this.isShared = true;
    }
    this.shareModeControl.setValue(this.injectedData.playlist.share_status?.mode || 'public');
    this.sharedEmails = this.injectedData.playlist.share_status?.allow_list || [];
  }

  public addEmail(): void {
    if (this.emailControl.valid) {
      if (this.sharedEmails.includes(this.emailControl.value)) {
        this.toastService.showErrorToast('Duplicate email');
        return;
      }
      if (this.isShared && this.injectedData.playlist.share_status?.mode === 'select') {
        let payload = {
          playlistId: this.injectedData.playlist.id,
          mode: this.shareModeControl.value,
          email: this.emailControl.value,
        };
        this.videoService.addEmailToSharedPlaylist(payload).subscribe((res) => {
          this.sharedEmails.push(this.emailControl.value);
          this.emailControl.setValue('');
          this.toastService.showToast('email added to shared list!');
        });
      } else {
        this.sharedEmails.push(this.emailControl.value);
        this.emailControl.setValue('');
      }
    }
  }

  public revoke(email: string): void {
    if (this.isShared) {
      let copy = this.sharedEmails.filter((e) => e != email);
      let payload = {
        playlistId: this.injectedData.playlist.id,
        mode: this.shareModeControl.value,
        allowList: copy,
      };
      this.videoService.sharePlaylist(payload).subscribe((res) => {
        this.sharedEmails = this.sharedEmails.filter((e) => e != email);
        if (this.sharedEmails.length) {
          this.toastService.showToast('Email removed from shared list!');
        } else {
          this.toastService.showToast('Playlist is no longer being shared!');
          this.isShared = false;
          this.modalService.onCloseSubject.next(true);
        }
      });
    } else {
      this.sharedEmails = this.sharedEmails.filter((e) => e != email);
    }
  }

  public share(): void {
    let payload = {
      playlistId: this.injectedData.playlist.id,
      mode: this.shareModeControl.value,
      allowList: this.shareModeControl.value === 'public' ? [] : this.sharedEmails,
    };
    this.videoService.sharePlaylist(payload).subscribe((res) => {
      this.toastService.showSuccessToast('Playlist shared!');
      this.linkControl.setValue(this.getLink());
      if (this.shareModeControl.value === 'public') {
        this.sharedEmails = [];
      } else {
        this.linkControl.setValue('');
      }
      this.isShared = true;
      this.modalService.clear(true);
    });
  }

  private getLink(): string {
    const noParamUrl = `${location.origin}/${constructSharePlaylistUrl()}`;
    const url = new URL(noParamUrl);
    url.searchParams.append('id', this.injectedData.playlist.id);
    return url.toString();
  }

  public stopSharing(): void {
    let payload = {
      playlistId: this.injectedData.playlist.id,
    };
    this.videoService.deleteSharePlaylist(payload).subscribe((res) => {
      this.toastService.showToast('Playlist is no longer being shared!');
      this.isShared = false;
      this.sharedEmails = [];
      this.linkControl.setValue('');
      this.modalService.onCloseSubject.next(true);
    });
  }

  public copyLink(): void {
    this.clipboard.copy(this.getLink());
    this.toastService.showToast('copied!');
  }
}
