import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ROUTER_UTILS } from 'src/app/core/utils/router.utils';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss',
})
export class NotFoundComponent {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  public goHome(): void {
    this.authService.isAuthenticated$.subscribe((isAuthenticated: boolean) => {
      if (isAuthenticated) {
        if (this.authService.getUserRole() === 'candidate') {
          this.router.navigate([
            ROUTER_UTILS.candidate.root,
            ROUTER_UTILS.candidate.dashboard.home.root,
          ]);
        } else {
          this.router.navigate([
            ROUTER_UTILS.recruiter.root,
            ROUTER_UTILS.recruiter.dashboard.home.root,
          ]);
        }
      } else {
        this.router.navigateByUrl('/');
      }
    });
  }
}
