import { trigger, style, transition, animate } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { ToastService } from '../toast.service';

export interface IToast {
  message: string;
  type: 'normal' | 'error' | 'success';
}
@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(2rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [animate('100ms ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ToastComponent {
  public showToast: boolean = false;
  public config?: IToast;

  constructor(private toastService: ToastService) {
    this.toastService.toastConfig.subscribe((config: IToast) => {
      this.config = config;
      this.showToast = true;
      setTimeout(() => (this.showToast = false), 5000);
    });
  }

  public close(): void {
    this.showToast = false;
  }
}
