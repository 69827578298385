import { Subscription, timer } from 'rxjs';
import { scan, takeWhile } from 'rxjs/operators';

import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { CountdownComponent, CountdownConfig, CountdownModule } from 'ngx-countdown';

import { AuthService } from 'src/app/core/services/auth/auth.service';
import { IdleTimeoutService } from 'src/app/core/services/idle-timeout/idle-timeout.service';
import { ModalService } from '../../modal.service';

import { ModalHeaderComponent } from '../../modal-header/modal-header.component';

@Component({
  selector: 'app-idle-time-out',
  templateUrl: './idle-timeout.component.html',
  styleUrls: ['./idle-timeout.component.scss'],
  standalone: true,
  imports: [CommonModule, ModalHeaderComponent, CountdownModule],
})
export class IdleTimeoutComponent implements OnInit, OnDestroy {
  timer: Subscription = new Subscription();

  counter: number = 10;

  countdownConfig: CountdownConfig = {
    demand: false,
    leftTime: 120,
    format: 'mm:ss',
  };

  constructor(
    public idleTimeOutService: IdleTimeoutService,
    private authService: AuthService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.startTimer();
  }

  ngOnDestroy(): void {
    this.timer.unsubscribe();
  }

  private startTimer(): void {
    this.counter = 0;
    this.timer.unsubscribe();
    this.timer = timer(0, 1000)
      .pipe(
        scan((acc) => --acc, 120),
        takeWhile((x) => x >= 0),
      )
      .subscribe((x) => {
        this.counter = x;
        if (this.counter === 0) {
          this.timer.unsubscribe();
          this.logoutUser();
          this.modalService.clear();
        }
      });
  }

  public cancel(): void {
    this.timer.unsubscribe();
    this.modalService.clear();
  }

  public logoutUser(): void {
    this.authService.logout();
    this.idleTimeOutService.stopTimer();
  }
}
