<div class="qa-prompt-container rounded-md">
  <app-modal-header title="Before you start..." [showCloseButton]="true"></app-modal-header>
  <div class="qa-prompt-body">
    <ul class="list-disc px-6">
      <li>
        The question is hidden at this stage. Once you see the question, you will have to submit an
        answer within the time limit.
      </li>
      <li>
        You will have
        <span class="text-gray-900">{{ injectedData.question.prep_time }} minutes</span> to prepare
        yourself.
      </li>
      <li>
        You will have
        <span class="text-gray-900">{{ injectedData.question.num_tries_max }} attempts</span> to
        answer the question.
      </li>
      <li>
        You video will be limited to
        <span class="text-gray-900">{{ injectedData.question.response_time_limit }} minutes</span>,
        you will see a timer once you start recording.
      </li>
    </ul>

    <br />

    <div class="footer" class="flex items-center justify-end gap-4">
      <button (click)="close()" class="secondary-btn">Go Back</button>
      <button (click)="onCommit()" class="primary-btn">Continue</button>
    </div>
  </div>
</div>
