import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';

import { VideoService } from 'src/app/candidate/services/video/video.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { ModalService } from '../../modal.service';

import { ModalHeaderComponent } from '../../modal-header/modal-header.component';
import { LoaderComponent } from '../../../loader/loader.component';

import { IVideoPlaylist } from 'src/app/core/models/video-api-payloads/video-playlist.model';

import { DATA_TOKEN } from '../../modal-container/modal-container.component';
import { ROUTER_UTILS } from 'src/app/core/utils/router.utils';

@Component({
  selector: 'app-add-to-playlist',
  templateUrl: './add-to-playlist.component.html',
  styleUrls: ['./add-to-playlist.component.scss'],
  standalone: true,
  imports: [CommonModule, ModalHeaderComponent, LoaderComponent],
})
export class AddToPlaylistComponent implements OnInit {
  public loading: boolean = true;
  playlists: IVideoPlaylist[] = [];

  data?: { ['id']: string };

  constructor(
    private videoService: VideoService,
    private toastService: ToastService,
    private router: Router,
    private modalService: ModalService,
    @Inject(DATA_TOKEN) private injectedData: { ['id']: string },
  ) {}

  ngOnInit() {
    this.data = this.injectedData;
    this.videoService.getPlaylists().subscribe((res) => {
      this.playlists = res;
      this.loading = false;
    });
  }

  public checkIfVideoInPlaylist(playlist: IVideoPlaylist): boolean {
    return playlist.videos.some((video) => video.id == this.data?.id);
  }

  public handleCheck(playlist: IVideoPlaylist, checked: boolean) {
    let payload = {
      videoId: this.data?.id,
      playlistId: playlist.id,
    };
    if (checked) {
      this.videoService
        .addVideoToPlaylist(payload)
        .subscribe(() => this.toastService.showToast('Video Added to Playlist'));
    } else {
      this.videoService
        .removeVideoFromPlaylist(payload)
        .subscribe(() => this.toastService.showToast('Video Removed from Playlist'));
    }
  }

  public newPlaylist(): void {
    this.router.navigate([
      ROUTER_UTILS.candidate.root,
      ROUTER_UTILS.candidate.dashboard.playlist.root,
      ROUTER_UTILS.candidate.dashboard.playlist.createPlaylist,
    ]);
    this.modalService.clear();
  }

  public close(): void {
    this.modalService.clear();
  }
}
