import { Component, Input } from '@angular/core';
import { ModalService } from '../modal.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-modal-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal-header.component.html',
  styleUrl: './modal-header.component.scss',
})
export class ModalHeaderComponent {
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() showCloseButton?: boolean;

  constructor(private modalService: ModalService) {}

  public close(): void {
    this.modalService.clear();
  }
}
