<div class="invite-applicant-container">
  <app-modal-header title="Invite applicant" [showCloseButton]="true"></app-modal-header>
  <div class="invite-applicant-body">
    <app-input
      [submitted]="emailChanged"
      [hint]="'Supports comma separated list of emails for mass invites'"
      [whiteBackground]="false"
      title="Email address"
      [control]="email"
    ></app-input>
    <app-input
      [submitted]="messageChanged"
      [whiteBackground]="false"
      title="Message"
      [largeText]="true"
      [control]="message"
    ></app-input>
    <div class="flex justify-end gap-4">
      <button class="secondary-btn" (click)="close()">Go Back</button>
      <button (click)="onInvite()" [disabled]="inviting" class="primary-btn flex items-center">
        <div
          *ngIf="inviting"
          class="mr-3 h-4 w-4 animate-spin rounded-full border-2 border-gray-400 border-t-white"
        ></div>
        Invite
      </button>
    </div>
  </div>
</div>
