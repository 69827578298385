import { delay, Observable, of, switchMap, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

import { AuthService } from '../../services/auth/auth.service';
import { ToastService } from '../../services/toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isAuthenticated$.pipe(
      switchMap((isAuthenticated) => {
        if (isAuthenticated === null) {
          // If null, wait until authentication state is resolved
          return of(false).pipe(delay(100)); // Adjust delay as needed
        }
        if (!isAuthenticated) {
          this.toastService.showErrorToast('You must be logged in to view this page.');
          this.router.navigateByUrl('/');
          return of(false);
        }
        return of(true);
      }),
    );
  }
}
