import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from '../services/auth/auth.service';
import { LocalstorageService } from '../services/local-storage/local-storage.service';

import { STORAGE_ITEM } from '../utils/local-storage.utils';

@Injectable({
  providedIn: 'root',
})
export class CloneHeaderService {
  constructor(
    private authService: AuthService,
    private localStorage: LocalstorageService,
  ) {}

  cloneHeader(request: HttpRequest<unknown>): Observable<HttpRequest<unknown>> {
    let isLoginUrl: boolean = request.url.includes('/auth/login');
    if (isLoginUrl) {
      return of(request);
    }

    return this.authService.isAuthenticated$.pipe(
      switchMap((isAuthenticated: boolean) => {
        if (isAuthenticated) {
          const clonedRequest: HttpRequest<unknown> = request.clone({
            setHeaders: {
              authorization: `Bearer ${this.localStorage.getItem(STORAGE_ITEM.access_token)}`,
            },
          });
          return of(clonedRequest);
        } else {
          return of(request);
        }
      }),
    );
  }
}
