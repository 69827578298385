import { skip } from 'rxjs';

import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';

import {
  ApplicationManagerService,
  IInviteApplicant,
} from 'src/app/recruiter/services/application-manager/application-manager.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { ModalService } from '../../modal.service';

import { ModalHeaderComponent } from '../../modal-header/modal-header.component';
import { InputComponent } from '../../../input-groups/input/input.component';

import { DATA_TOKEN } from '../../modal-container/modal-container.component';
import { decodeParam } from 'src/app/core/utils/paramEncoder';
import { CommonModule } from '@angular/common';

export function ValidateUrl(control: AbstractControl): null | { invalidUrlList: boolean } {
  const regex = /\S+@\S+\.\S+/;
  const emails = control.value?.split(',').map((v: string) => v.trim());
  const res = emails?.every((email: string) => {
    if (regex.test(email)) return true;
    return false;
  });

  if (res) return null;
  return { invalidUrlList: true };
}

@Component({
  selector: 'app-invite-applicant',
  templateUrl: './invite-applicant.component.html',
  styleUrls: ['./invite-applicant.component.scss'],
  standalone: true,
  imports: [ModalHeaderComponent, InputComponent, CommonModule],
})
export class InviteApplicantComponent implements OnInit {
  public inviting: boolean = false;

  public email: FormControl = new FormControl(
    '',
    Validators.compose([Validators.required, ValidateUrl]),
  );
  public message: FormControl = new FormControl(
    'Hi, I would like to invite you to apply to this job, Thanks.',
    Validators.compose([Validators.required]),
  );

  public emailChanged: boolean = false;
  public messageChanged: boolean = false;

  constructor(
    private applicationService: ApplicationManagerService,
    private toastService: ToastService,
    @Inject(DATA_TOKEN) public injectedData: { ['job']: URL },
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.email.valueChanges.pipe(skip(1)).subscribe(() => {
      this.emailChanged = true;
      this.cdr.detectChanges();
    });
    this.message.valueChanges.subscribe(() => {
      this.messageChanged = true;
      this.cdr.detectChanges();
    });
  }

  public onInvite(): void {
    if (this.email.valid && this.message.valid) {
      this.inviting = true;
      const payload: IInviteApplicant = {
        emails: this.email.value?.split(',').map((v: string) => v.trim()) as string[],
        message: this.message.value as string,
        job: this.injectedData.job.toString(),
        jobId: JSON.parse(decodeParam(this.injectedData.job.searchParams.get('meta') as string))[
          'id'
        ],
      };
      this.applicationService.inviteApplications(payload).subscribe({
        next: () => {
          this.toastService.showSuccessToast('Invitation Sent!');
          this.inviting = false;
          this.close();
        },
        error: () => {
          this.toastService.showErrorToast('Please try again!');
          this.inviting = false;
          this.close();
        },
      });
    }
  }

  public close(): void {
    this.modalService.clear();
  }
}
