<div class="video-wrapper">
  <!-- Loading Spinner or Placeholder -->
  <div class="loading-placeholder" *ngIf="!videoLoaded">
    <app-loader></app-loader>
    <!-- You can use a spinner or any placeholder here -->
  </div>

  <!-- Video Element -->
  <video
    #videoPlayer
    class="video-element"
    [class.loaded]="videoLoaded"
    (loadeddata)="onLoadedData()"
    controls
    muted
  >
    <source *ngIf="url" [src]="url" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>
