import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

import { ToastService } from 'src/app/core/services/toast/toast.service';

import { ModalHeaderComponent } from '../../modal-header/modal-header.component';

import { DATA_TOKEN } from '../../modal-container/modal-container.component';

@Component({
  selector: 'app-share-video',
  templateUrl: './share-video.component.html',
  styleUrls: ['./share-video.component.scss'],
  standalone: true,
  imports: [CommonModule, ModalHeaderComponent],
})
export class ShareVideoComponent {
  constructor(
    @Inject(DATA_TOKEN) public injectedData: { ['url']: string },
    private clipboard: Clipboard,
    private toastService: ToastService,
  ) {}

  public copy(): void {
    this.clipboard.copy(this.injectedData.url);
    this.toastService.showToast('Copied!');
  }
}
