import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appScrollTracker]',
  standalone: true,
})
export class ScrollTrackerDirective {
  @Output() scrolled = new EventEmitter();

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    if (event.target.scrollWidth == Math.ceil(event.target.scrollLeft + event.target.offsetWidth)) {
      this.scrolled.emit();
    }
  }
}
