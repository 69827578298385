import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

import { AddToPlaylistComponent } from './components/add-to-playlist/add-to-playlist.component';
import { ShareVideoComponent } from './components/share-video/share-video.component';
import {
  ConfirmationPromptComponent,
  IConfirmationPrompt,
} from './components/confirmation-prompt/confirmation-prompt.component';
import { ModalVideoPlayerComponent } from './components/modal-video-player/modal-video-player.component';
import { SharePlaylistComponent } from './components/share-playlist/share-playlist.component';
import { AddVideoToPlaylistComponent } from './components/add-video-to-playlist/add-video-to-playlist.component';
import { QaConfirmationPromptComponent } from './components/qa-confirmation-prompt/qa-confirmation-prompt.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { QaVideoSelectComponent } from './components/qa-video-select/qa-video-select.component';
import { IdleTimeoutComponent } from './components/idle-timeout/idle-timeout.component';

import { IQuestion } from 'src/app/core/models/question.model';
import { ISideNavItem } from 'src/app/core/models/sidebar-interface';
import { IVideoPayload } from 'src/app/core/models/video-api-payloads/video.model';
import { IVideoPlaylist } from 'src/app/core/models/video-api-payloads/video-playlist.model';
import { IVideoObject } from 'src/app/candidate/dashboard/pages/my-applications/video-reply/video-reply.component';
import { InviteApplicantComponent } from './components/invite-applicant/invite-applicant.component';
import { QuestionComponent } from './components/question/question.component';

export interface IModalConfig {
  component: any;
  data: any;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalSubject = new BehaviorSubject<IModalConfig | undefined>(undefined);

  public onCloseSubject = new Subject<boolean>();
  public modalOpened$: Observable<IModalConfig | undefined> = this.modalSubject.asObservable();

  public openIdleTimeOutModal(): void {
    this.modalSubject.next({
      component: IdleTimeoutComponent,
      data: {},
    });
  }

  public openVideoSelectPrompt(data: IVideoObject[]): Observable<any> {
    this.onCloseSubject = new Subject<boolean>();
    this.modalSubject.next({
      component: QaVideoSelectComponent,
      data: { answers: data },
    });

    return this.onCloseSubject.asObservable();
  }

  public openAddToPlaylistModal(id?: string): void {
    this.modalSubject.next({ component: AddToPlaylistComponent, data: { id } });
  }

  public openShareVideoModal(url: string): void {
    this.modalSubject.next({ component: ShareVideoComponent, data: { url } });
  }

  public openConfirmationModal(prompt: IConfirmationPrompt): Observable<boolean> {
    this.onCloseSubject = new Subject<boolean>();
    this.modalSubject.next({
      component: ConfirmationPromptComponent,
      data: { prompt },
    });

    return this.onCloseSubject.asObservable();
  }

  public openVideoModal(video: IVideoPayload): void {
    this.modalSubject.next({
      component: ModalVideoPlayerComponent,
      data: { video },
    });
  }

  public openQAConfirmationPrompt(data: IQuestion): Observable<boolean> {
    this.onCloseSubject = new Subject<boolean>();
    this.modalSubject.next({
      component: QaConfirmationPromptComponent,
      data: { question: data },
    });

    return this.onCloseSubject.asObservable();
  }

  public openFeedbackPrompt(): void {
    this.modalSubject.next({
      component: FeedbackComponent,
      data: {},
    });
  }

  public openQuestionModal(): Observable<any> {
    this.onCloseSubject = new Subject<boolean>();
    this.modalSubject.next({
      component: QuestionComponent,
      data: {},
    });

    return this.onCloseSubject.asObservable();
  }

  openMessageRecruiterModal() {
    // const dialogRef = this.dialog.open(MessageRecruiterComponent, {
    //   width: "50%",
    //   autoFocus: false,
    // });
    // return dialogRef.afterClosed();
  }

  public openAddVideosToPlaylistModal(
    id: string,
    selectedVids: IVideoPayload[],
  ): Observable<boolean> {
    this.onCloseSubject = new Subject<boolean>();
    this.modalSubject.next({
      component: AddVideoToPlaylistComponent,
      data: { id: id, videos: selectedVids },
    });

    return this.onCloseSubject.asObservable();
  }

  public openInviteApplicantModal(jobLink: URL): Observable<boolean> {
    this.onCloseSubject = new Subject<boolean>();
    this.modalSubject.next({
      component: InviteApplicantComponent,
      data: { job: jobLink },
    });

    return this.onCloseSubject.asObservable();
  }

  public openSharePlaylistModal(playlist: IVideoPlaylist): Observable<boolean> {
    this.onCloseSubject = new Subject<boolean>();
    this.modalSubject.next({
      component: SharePlaylistComponent,
      data: { playlist },
    });

    return this.onCloseSubject.asObservable();
  }

  public clear(data?: any): void {
    this.modalSubject.next(undefined);
    this.onCloseSubject.next(data);
  }
}
