<div class="flex h-screen items-center justify-center space-x-2 bg-white">
  <span class="sr-only">Loading...</span>
  <div
    class="h-8 w-8 animate-bounce rounded-full bg-black [animation-delay:-0.3s]"
  ></div>
  <div
    class="h-8 w-8 animate-bounce rounded-full bg-black [animation-delay:-0.15s]"
  ></div>
  <div class="h-8 w-8 animate-bounce rounded-full bg-black"></div>
</div>
